import React from "react"

function LinkIcon(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 8a1 1 0 01-1 1H5v10h10v-6a1 1 0 012 0v6a2 2 0 01-2 2H5a2 2 0 01-2-2V9c0-1.1.9-2 2-2h6a1 1 0 011 1z" />
      <path d="M19 6.41L8.7 16.71a1 1 0 11-1.4-1.42L17.58 5H14a1 1 0 010-2h6a1 1 0 011 1v6a1 1 0 01-2 0V6.41z" />
    </svg>
  )
}

export default LinkIcon
